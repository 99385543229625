"use client";
import { useEffect, useRef, useState } from "react";
import IconArrowChevronLeftWhite from "@/components/Icon/IconArrowChevronLeftWhite.svg";
import IconArrowChevronRightWhite from "@/components/Icon/IconArrowChevronRightWhite.svg";
import IconStarGray from "@/components/Icon/IconStarGray.svg";
import IconStarGold from "@/components/Icon/IconStarGold.svg";

import classNames from "classnames";
import styles from "./styles.module.scss";
import Image from "next/image";

export default function Testimonials() {
  const [reviews, setReviews] = useState([
    {
      rating: 5,
      content:
        "Was delivered within 3 days ,excellent fit ,better than I thought it would be sparkly, packaged well, the box came undamaged.Where we live is quite remote so dealing with bike biz is an absolute pleasure, Thank You",
    },
    {
      rating: 5,
      content:
        "Received the cover really fast. Shipping was excellent! Over all the cover is perfect, fits perfect with my helmet box. Cant ask much more. Only thing zipper on the storage bag broke.... first world problem though.",
    },
    {
      rating: 4.5,
      content: "Very good product, fits well with no issues. delivery was very fast.",
    },
    {
      rating: 4.5,
      content: "Most comfortable jacket on the market, best price from bikebiz and super fast delivery .",
    },
  ]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [goToNextSlide, setGoToNextSlide] = useState();

  const slidesRef = useRef();
  const slidesContainerRef = useRef();
  const [slidesLength, setSlidesLength] = useState();
  const [startX, setStartX] = useState(null);
  const [endX, setEndX] = useState(null);
  const [translateX, setTranslateX] = useState(0);

  const handleResize = () => {
    const containerWidth = slidesContainerRef.current.getBoundingClientRect().width;
    const itemWidth = document.querySelector(".review-slide").offsetWidth;
    const itemsCount = parseInt(containerWidth / itemWidth);
    setSlidesLength(Math.ceil(reviews.length / itemsCount));
  };

  const handleNext = () => {
    if (currentSlide >= slidesLength - 1) return;
    goToSlide(currentSlide + 1);
    setGoToNextSlide(true);
  };
  const handlePrev = () => {
    if (currentSlide <= 0) return;
    goToSlide(currentSlide - 1);
    setGoToNextSlide(false);
  };

  const goToSlide = index => {
    const slideWidth = slidesContainerRef.current.getBoundingClientRect().width;
    var updatedTranslateX = -slideWidth * index;
    setTranslateX(updatedTranslateX);
    slidesRef.current.style.transform = `translateX(${updatedTranslateX}px)`;
    setCurrentSlide(index);
  };

  const handleTouchStart = e => {
    const touchStartX = e.touches[0].clientX;
    setStartX(touchStartX);
  };

  const handleTouchMove = e => {
    const slideWidth = slidesContainerRef.current.getBoundingClientRect().width;
    const touchMoveX = e.touches[0].clientX;

    if (Math.abs(touchMoveX - startX) <= slideWidth / 2 && currentSlide >= 0 && currentSlide <= slidesLength - 1) {
      slidesRef.current.style.transform = `translateX(${touchMoveX - startX + translateX}px)`;
    }
    setEndX(touchMoveX);
  };
  const handleTouchEnd = e => {
    if (startX - endX > 50 && currentSlide < slidesLength - 1) {
      handleNext();
    } else if (startX - endX < -50 && currentSlide > 0) {
      handlePrev();
    } else {
      slidesRef.current.style.transform = `translateX(${translateX}px)`;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="block bg-black mx-4 lg:mx-8 mt-[73px]">
      <div className="container mx-auto" style={{ padding: 0 }}>
        <div className="h-auto bg-black px-[16px] lg:px-0 pb-[41px] lg:pb-[100px] relative">
          <div className="flex items-start justify-start pt-[64px] lg:pt-[99px]">
            <div className={classNames(styles.testimonialsTextDesktop)}>
              <p className="font-[600] font-rajdhani text-[26px] lg:text-[38px] leading-[22px]">
                What our Customers Say
              </p>
            </div>
          </div>
          <div className="relative">
            <div
              ref={slidesContainerRef}
              className="px-[16px] lg:px-0 mt-[49px] lg:mt-[80px] w-full lg:[672px] xl:w-[1008px]"
            >
              <div className="overflow-hidden ">
                <div
                  ref={slidesRef}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                  className={classNames(styles.reviewSlides)}
                >
                  {reviews.map((review, index) => (
                    <div
                      key={`review-${index}`}
                      className="review-slide  max-w-[500px] lg:max-w-[336px] min-w-full lg:min-w-[336px] pt-0 lg:pt-[29px] text-center pl"
                    >
                      <div className=" flex flex-col items-center lg:items-start">
                        <div className="flex gap-1">
                          {[...Array(5)].map((_, index) =>
                            index < review.rating ? (
                              <Image key={`star-${index}`} src={IconStarGold.src} alt="" width={14} height={14} />
                            ) : (
                              <Image key={`star-${index}`} src={IconStarGray.src} alt="" width={14} height={14} />
                            )
                          )}
                        </div>
                        <div className="mt-[10px] mx-auto font-[300] text-grey text-base font-roboto text-center lg:text-left pr-4">
                          {review.content}
                        </div>
                        <div className="mt-[18px] text-[10px] font-bold text-gold"></div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className={classNames(styles.owlNav, "hidden lg:flex")}>
                  <div
                    className={classNames(styles.owlPrev, goToNextSlide === true && styles.active)}
                    onClick={handleNext}
                  >
                    <Image
                      src={IconArrowChevronRightWhite.src}
                      width="16"
                      height="16"
                      alt="icon-arrow-chevron-right"
                      className="w-4 h-4"
                    />
                  </div>
                  <div
                    className={classNames(styles.owlNext, goToNextSlide == false && styles.active)}
                    onClick={handlePrev}
                  >
                    <Image
                      src={IconArrowChevronLeftWhite.src}
                      width="16"
                      height="16"
                      alt="icon-arrow-chevron-left"
                      className="w-4 h-4"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
