"use client";
import classNames from "classnames";
import Image from "next/image";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import IconArrowChevronRight from "@/components/Icon/IconArrowChevronRight.svg";
import IconArrowChevronLeft from "@/components/Icon/IconArrowChevronLeft.svg";
import Link from "next/link";

export default function BrandCarousel({ brands = [] }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleCarouselChange = index => {
    setCurrentIndex(index);
  };

  const handleNextClick = () => {
    if (currentIndex === 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevClick = () => {
    if (currentIndex === 0) {
      setCurrentIndex(1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
      <div className="w-full hidden xl:flex flex-col">
        <Carousel
          showArrows={true} // Show the arrows
          showStatus={false} // Hide the status
          onChange={handleCarouselChange}
          selectedItem={currentIndex}
        >
          <div className="flex justify-center">
            {brands.slice(0, 5).map((brand, index) => (
              <Link href={`/brands/${brand.brand_link}`} key={`brand-${index}`}>
                <div
                  className={classNames("border-grey-200", {
                    "border-l-[1px]": index !== 0,
                  })}
                >
                  <Image
                    src={brand.brand_logo.url}
                    height="180"
                    width="180"
                    className={classNames("h-[180px] max-w-[180px] relative", {
                      "border-l-[1px]": index !== 0,
                    })}
                    alt={brand.brand_logo.alt || ""}
                  />
                </div>
              </Link>
            ))}
          </div>
          <div className="flex justify-center">
            {brands.slice(5, 10).map((brand, index) => (
              <Link href={`/brand/${brand.brand_link}`} key={`brand-${index}`}>
                <div
                  className={classNames("border-grey-200", {
                    "border-l-[1px]": index !== 0,
                  })}
                >
                  <Image
                    src={brand.brand_logo.url}
                    height="180"
                    width="180"
                    className={classNames("h-[180px] max-w-[180px] relative", {
                      "border-l-[1px]": index !== 0,
                    })}
                    alt={brand.brand_logo.alt || ""}
                  />
                </div>
              </Link>
            ))}
          </div>
        </Carousel>
        <div className="w-full flex justify-center items-center gap-8 mx-auto mt-10">
          <button
            onClick={handlePrevClick}
            className={classNames({
              "invisible ": currentIndex === 0,
            })}
          >
            <Image
              src={IconArrowChevronLeft.src}
              width="12"
              height="12"
              className="w-[14px] h-[14px]"
              alt="icon-arrow-chevron-left"
            />
          </button>
          <div className="h-[2px] w-1/2 bg-grey-400 flex">
            {
              // Progress bar
              Array.from({ length: Math.ceil(brands.length / 5) }, (_, index) => (
                <div
                  key={`progress-bar-${index}`}
                  className={classNames("h-[4px] -mt-[3px] flex", {
                    "invisible ": index !== currentIndex,
                  })}
                  style={{ width: `${100 / Math.ceil(brands.length / 5)}%` }}
                >
                  <div className="w-8/12 h-full bg-black"></div>
                  <div className="w-4/12 h-full bg-gold"></div>
                </div>
              ))
            }
          </div>
          <button
            onClick={handleNextClick}
            className={classNames({
              "invisible ": currentIndex >= brands.length / 5 - 1,
            })}
          >
            <Image
              src={IconArrowChevronRight.src}
              width="12"
              height="12"
              className="w-[14px] h-[14px]"
              alt="icon-arrow-chevron-right"
            />
          </button>
        </div>
        <p className="text-center  font-bold text-[10px]">
          {String(currentIndex + 1).padStart(2, "0")}
          <span className="text-grey-200 font-bold text-[10px]">
            /{String(Math.ceil(brands.length / 5)).padStart(2, "0")}
          </span>
        </p>
      </div>
      <div className="w-full flex xl:hidden flex-col">
        <Carousel onChange={handleCarouselChange} selectedItem={currentIndex}>
          <div className="flex justify-center">
            {brands.slice(0, 3).map((brand, index) => (
              <Link href={`/brand/${brand.brand_link}`} key={`brand-${index}`}>
                <div
                  className={classNames("border-grey-200", {
                    "border-l-[1px]": index !== 0,
                  })}
                >
                  <Image
                    src={brand.brand_logo.url}
                    height="180"
                    width="180"
                    className={classNames("h-[180px] max-w-[180px] relative", {
                      "border-l-[1px]": index !== 0,
                    })}
                    alt={brand.brand_logo.alt || ""}
                  />
                </div>
              </Link>
            ))}
          </div>
          <div className="flex justify-center">
            {brands.slice(3, 6).map((brand, index) => (
              <Link href={`/brand/${brand.brand_link}`} key={`brand-${index}`}>
                <div
                  className={classNames("border-grey-200", {
                    "border-l-[1px]": index !== 0,
                  })}
                >
                  <Image
                    src={brand.brand_logo.url}
                    height="180"
                    width="180"
                    className={classNames("h-[180px] max-w-[180px] relative", {
                      "border-l-[1px]": index !== 0,
                    })}
                    alt={brand.brand_logo.alt || ""}
                  />
                </div>
              </Link>
            ))}
          </div>
        </Carousel>
        <div className="w-full flex justify-center items-center gap-8 mx-auto mt-10">
          <button
            onClick={handlePrevClick}
            className={classNames({
              "invisible ": currentIndex === 0,
            })}
          >
            <Image
              src={IconArrowChevronLeft.src}
              width="12"
              height="12"
              className="w-[14px] h-[14px]"
              alt="icon-arrow-chevron-left"
            />
          </button>
          <div className="h-[2px] w-1/2 bg-grey-400 flex">
            {
              // Progress bar
              Array.from({ length: Math.ceil(brands.length / 3) }, (_, index) => (
                <div
                  key={`progress-bar-${index}`}
                  className={classNames("h-[4px] -mt-[3px] flex", {
                    "invisible ": index !== currentIndex,
                  })}
                  style={{ width: `${100 / Math.ceil(brands.length / 3)}%` }}
                >
                  <div className="w-8/12 h-full bg-black"></div>
                  <div className="w-4/12 h-full bg-gold"></div>
                </div>
              ))
            }
          </div>
          <button
            onClick={handleNextClick}
            className={classNames({
              "invisible ": currentIndex >= brands.length / 3 - 1,
            })}
          >
            <Image
              src={IconArrowChevronRight.src}
              width="12"
              height="12"
              className="w-[14px] h-[14px]"
              alt="icon-arrow-chevron-right"
            />
          </button>
        </div>
        <p className="text-center  font-bold text-[10px]">
          {String(currentIndex + 1).padStart(2, "0")}
          <span className="text-grey-200 font-bold text-[10px]">
            /{String(Math.ceil(brands.length / 3)).padStart(2, "0")}
          </span>
        </p>
      </div>
    </>
  );
}
